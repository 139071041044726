import { useEffect, useState } from 'react';
import './App.scss';
import Header from './components/header';
import Footer from './components/footer';
import Section from './components/section'; 
import Instructions from './components/download_instructions';
import PlayerIframe from './components/playerIframe';
import NotFound from './components/404';
import bgimage from './assets/bgRecurso 3@2x.png'
import {BrowserRouter, Routes, Route} from 'react-router-dom'

function App() {

  const[lang, setLang] = useState(localStorage.getItem('lang'))
  useEffect(() => {
      if(localStorage.getItem('lang') === null){
          console.log('check null');
          localStorage.setItem('lang', 'es')
          return setLang('es')
      } else {
      
      let storedLang = localStorage.getItem('lang')
      return setLang(storedLang)
      }
  }, [setLang])

  return (
    <BrowserRouter>
      <div className="App" id='top'>
        <img className='bgimage' src={bgimage} alt='backgorund'/>
        <Header lang={lang} setLang={setLang}/>
          <Routes>
            <Route path='/radio-mitre-vivo' element={<PlayerIframe />} />
            <Route path='/download' element={<Instructions />} />
            <Route path='/' element={<Section />}/>
            <Route path='*' element={<NotFound />} />
          </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
