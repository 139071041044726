import '../styles/header.scss';
import {Form} from 'react-bootstrap'
import {BsList, BsXLg} from 'react-icons/bs'
import HealthCheckLogo from '../assets/HealthLogoTexto_fondooscuro.png'
import HealthCheckLogoSm from '../assets/HealthLogo_fondooscuro.png'
import i18n from '../i18n/index'
import { useState } from 'react';

function Header({lang, setLang}) {
    const [menuOpen, setMenuOpen] = useState(false)
    const setNewLang = (newLang) => {
        localStorage.setItem('lang', newLang)
        setLang(newLang)
        window.location.reload()
    }

    const handleOpen = () => {
        setMenuOpen(!menuOpen)
    }

    const goToInstructions = (offset) => {
        menuOpen && handleOpen()
        window.scrollTo(0, ((document.body.offsetHeight)-offset))
    }

    const createAnchor = (anchor) => {
        let section = i18n.store.data.es.translation[`section_${anchor}`]
        return section ? true : false
    }

    return (
        <>

            {/* Google Tag Manager */}
            <script>{`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-N6734GSK');
            `}</script>
            {/* End Google Tag Manager */}

            <div className="navbar container">
                <div className='container'>
                    <div className='title_container'>
                        <a href='/'>
                            <img src={HealthCheckLogo} className='Logo' alt='Spell check Icon' />
                        </a>
                    </div>

                    <div className='anchor_container'>
                        {createAnchor('about') && <a className='a a-text' href='#about'>{i18n.t('navbar.anchors.a1')}</a>}
                        {createAnchor('benefits') && <a className='a a-text' href='#benefits'>{i18n.t('navbar.anchors.a2')}</a>}
                        {createAnchor('clients') && <a className='a a-text' href='#clients'>{i18n.t('navbar.anchors.a3')}</a>}
                        {createAnchor('faqs') && <a className='a a-text' href='#faqs'>{i18n.t('navbar.anchors.a4')}</a>}
                        {createAnchor('news') && <a className='a a-text' href='#news'>{i18n.t('navbar.anchors.a5')}</a>}
                    </div>

                    <div className='button_container'>
                        {/*<a className='b-float' href='/login'>{i18n.t('navbar.buttons.b1')}</a>*/}
                        <a className='b-filled' 
                            href='https://drive.google.com/file/d/1Lzor46NsC7v9y2ogjN0qzX78sLjXlV_X/view?usp=sharing'
                            target='_blank' 
                            rel="noreferrer"
                            onClick={() => goToInstructions(1000)}
                        >
                            {i18n.t('navbar.buttons.b2')}
                        </a>
                        <Form.Select aria-label='Languaje selector' value={lang} onChange={e => setNewLang(e.target.value)} className='b-float language'>
                            <option value='es'>ES</option>
                            <option value='en'>EN</option>
                        </Form.Select>
                    </div>
                </div>
            </div>
            <div className="navbar navbar-menu" >
                <div className='container'>
                    <div className='header'>
                        <div>
                            <button className='b-float' onClick={handleOpen}><BsList size='32px'/></button>
                        </div>

                        <div className='title_container'>
                            <a href='/'>
                                <img src={HealthCheckLogoSm} className='Logo' alt='Spell check Icon' />
                            </a>
                        </div>
                    </div>

                    <div className={`menu ${menuOpen? 'open':'close'}`}>
                        <div className='head'>
                            <button className='b-float' onClick={handleOpen}> <BsXLg size='23px' /></button>
                            <Form.Select aria-label='Languaje selector' value={lang} onChange={e => setNewLang(e.target.value)} className='b-float language'>
                                <option value='es'>ES</option>
                                <option value='en'>EN</option>
                            </Form.Select>
                        </div>
                        <div className='anchor_container'>
                            {createAnchor('about') && <a className='a a-text' href='#about' onClick={handleOpen}>{i18n.t('navbar.anchors.a1')}</a>}
                            {createAnchor('benefits') && <a className='a a-text' href='#benefits' onClick={handleOpen}>{i18n.t('navbar.anchors.a2')}</a>}
                            {createAnchor('clients') && <a className='a a-text' href='#clients' onClick={handleOpen}>{i18n.t('navbar.anchors.a3')}</a>}
                            {createAnchor('faqs') && <a className='a a-text' href='#faqs' onClick={handleOpen}>{i18n.t('navbar.anchors.a4')}</a>}
                            {createAnchor('news') && <a className='a a-text' href='#news' onClick={handleOpen}>{i18n.t('navbar.anchors.a5')}</a>}
                        </div>

                        <div className='button_container'>
                            {/* <a className='b-float' href='/login' onClick={handleOpen}>{i18n.t('navbar.buttons.b1')}</a> */}
                            <a 
                                className='b-float' 
                                href='https://drive.google.com/file/d/1Lzor46NsC7v9y2ogjN0qzX78sLjXlV_X/view?usp=sharing' 
                                target='_blank' 
                                rel="noreferrer" 
                                onClick={() => goToInstructions(1700)}
                            >
                                {i18n.t('navbar.buttons.b2')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
