export const esp = {
    navbar: {
        anchors: {
            a1: 'Qué es',
            a2: 'Beneficios',
            a3: 'Nuestros Clientes',
            a4: 'Faqs',
            a5: '¡Novedades!'
        },
        buttons: {
            b1: 'Iniciar Sesión',
            b2: 'Descargar'
        }
    },
    section_intro: {
        anchors:{},
        buttons:{
            b1: 'Quiero conocer más'
        },
        headers:{
            h1: 'Mejora tus notas'
        },
        texts:{
            t1: 'Asistencia para la creación y desarrollo de notas en ARC XP'
        },
    },
    section_resume: {
        anchors:{},
        buttons:{},
        headers:{
            h1: 'Mejor posicionamiento SEO',
            h2: 'Revisión y sugerencia de ortografía',
            h3: 'Integración con Google Trends'
        },
        texts:{
            t1: 'Prepara tu contenido para clasificar con el análisis SEO y mejorar tu ranking',
            t2: 'Revisión y sugerencia de mejora ortográficas. Marcado de errores y posibles soluciones. Multi idioma.',
            t3: 'Utilizamos Google Trands para mejorar el posicionamiento, utilizando las palabras más buscadas.'
        },
    },
    section_about: {
        anchors:{},
        buttons:{},
        headers:{
            h1: '¿Qué es Health Check?'
        },
        texts:{
            t1: 'Es una herramienta que asiste a los redactores en la creación y desarrollo de notas.',
            t2: 'Solucionando problemas típicos con sugerencias de ortografía, palabras más buscadas y posicionamiento SEO por medio de análisis de las mismas'
        }
    },
    section_stars_break: {
        anchors:{},
        buttons:{},
        headers:{
            h1: 'Conoce el rendimiento de tu equipo de redacción'
        },
        texts:{}
    },
    section_benefits: {
        anchors:{},
        buttons:{},
        headers:{
            h1: 'Beneficios del Health Check',
            h2: '1',
            h3: '2',
            h4: '3',
            h5: '4',
            h6: '5',
            h7: '6',
        },
        texts:{
            t1: 'Encuentre palabras clave de alto rendimiento que mejoren la calidad y la relevancia de su contenido.',
            t2: 'Prepara tu contenido para clasificar con el análisis SEO.',
            t3: 'Verificación de links e imágenes con vista previa.',
            t4: 'Optimización del contenido.',
            t5: 'Revisión y sugerencia de ortografía.',
            t6: 'Integración con Google Trends para las palabras más buscadas',
        }
    },
    section_clients: {
        anchors:{},
        buttons:{},
        headers:{
            h1: 'Algunos de nuestros clientes'
        },
        texts:{
            t1: '* Disponible para usuarios de ARC Publishing con Google Chorme',
        }
    },
    section_faqs: {
        anchors:{},
        buttons:{},
        headers:{
            h1: 'Faqs',
            h2: '1. ¿Cómo puedo registrarme?',
            h3: '1. ¿Cómo instalar la extensión del Health Check?',
            h4: '2. ¿Cómo analizar una nota?',
            h5: '3. ¿Por qué si tengo la mayor cantidad de campos obligatorios cumplidos, no me aparece la puntuación de estrellas?',
        },
        texts:{
            t1: {
                p: 'Para que puedas acceder a la extensión, ',
                b: 'debes solicitar el usuario a email@rmail.com.'},
            t2: {
                p: 'Por el momento la extensión ',
                b: 'sólo está disponible para usuarios de Google Chrome y de ARC XP',
                li1: {
                    p: 'el archivo de la extensión',
                    b: 'Descargar '
                },
                li2: {
                    p: 'Abrir el ',
                    b: 'Navegador Chrome'
                },
                li3: {
                    p: 'Click en los ',
                    b: '3 puntos en la esquina superior derecha.'
                },
                li4: {
                    p: 'Ir a Herramientas ->',
                    b: 'Extensiones'
                },
            },
            t3: {
                p1: 'Luego de realizar su redacción, ',
                b1: 'dar click en Guardar.',
                p2: 'Aparecerá una ventana en la parte inferior de la pantalla donde se cargará el resultado de su nota analizada y ',
                b2: 'podrá verificar qué elementos tiene correctos y cuáles no.',
                p3: 'Además podrá ',
                b3: 'verificar la puntuación ',
                p4: 'otorgada a su nota.'
            },
            t4: {
                p1: 'La sección de ',
                b: 'campos obligatorios debe estar completa ',
                p2: 'para que se pueda activar la puntuación de la nota, ya que estos son los requerimientos mínimos.'
            },   
        }
    },
    footer: {
        anchors:{
            a1: 'Que es',
            a2: 'Beneficios',
            a3: 'Nuestros Clientes',
            a4: 'Faqs'
        },
        buttons: {},
        headers: {
            h1: 'Acerca de Health Check'
        },
        texts: {}
    },
    404: {
        anchors:{
            a1: 'Volver al inicio'
        },
        buttons:{},
        headers: {
            h1: 'Oosp! 404'
        },
        texts: {
            t1: 'No pudimos encontrar la página que estás buscando'
        }
    },
    paths: {
        download: '/descarga',
    }
}