import '../styles/section.scss';
import Logo from '../assets/HealthLogo.png'
import about from '../assets/Enmascarar grupo 1.png'
import i18n from '../i18n/index'

function SectionAbout() {


    return (
        <section className="section_container">
            <div className='about_container' id='about'>
                <div className='about_content'>
                    <img src={Logo} className='icon' alt='Spell check Icon' />
                    <h2 className='h2'>{i18n.t('section_about.headers.h1')}</h2>
                    <p className='texto1'>{i18n.t('section_about.texts.t1')}</p>
                    <p className='texto1'>{i18n.t('section_about.texts.t2')}</p>
                </div>
                <img src={about} className='analysis' alt='Analysis preview' />
            </div>
        </section>
    );
}

export default SectionAbout;