import React from 'react'

const PlayerIframe = () => {
    return (
        <div className='iframeContainer'>
            <iframe
          src="https://radiomitre.cienradios.com/player-mitre-clarin/"
	    id="player-mitre"
            title="Player"
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer"
            ></iframe>
        </div>
    )
}

export default PlayerIframe
