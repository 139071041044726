import '../styles/section.scss';
import icon_SEO from '../assets/Icon awesome-laptop-code.svg'
import icon_SPELL from '../assets/Icon awesome-spell-check.svg'
import icon_GTRENDS from '../assets/Icon awesome-chart-area.svg'
import i18n from '../i18n/index'

function SectionResume() {


    return (
        <section className="section_container">
            <div className='resume_container' id='resume'>
                <div className='container'>
                    <img src={icon_SEO} className='icon i1' alt='SEO Icon' />
                    <h1 className='h2'>{i18n.t('section_resume.headers.h1')}</h1>
                    <p className='texto1'>{i18n.t('section_resume.texts.t1')}</p>
                </div>
                <div className='container'>
                    <img src={icon_SPELL} className='icon i2' alt='Spell check Icon' />
                    <h1 className='h2'>{i18n.t('section_resume.headers.h2')}</h1>
                    <p className='texto1'>{i18n.t('section_resume.texts.t2')}</p>
                </div>
                <div className='container'>
                    <img src={icon_GTRENDS} className='icon i3' alt='Google trends Icon' />
                    <h1 className='h2'>{i18n.t('section_resume.headers.h3')}</h1>
                    <p className='texto1'>{i18n.t('section_resume.texts.t3')}</p>
                </div>
            </div>
        </section>
    );
}

export default SectionResume;