import '../styles/section.scss';
import i18n from '../i18n/index'

function SectionNews() {
    if(!i18n.store.data.es.translation.section_news || !i18n.store.data.es.translation.section_news){
        return null
    }

    return (
        <section className="section_container col_section">
            <div className='faqs_container' id='news'>
                <h1 className='h2'>{i18n.t('section_faqs.headers.h1')}</h1>
                <div className='faqs_content'>
                    <div>
                        <div className='faq'>
                            <h1 className='h3'>{i18n.t('section_faqs.headers.h2')}</h1>
                            <p className='texto1'>{i18n.t('section_faqs.texts.t1.p')} <b>{i18n.t('section_faqs.texts.t1.b')}</b></p>
                        </div>
                        <div className='faq'>
                            <h1 className='h3'>{i18n.t('section_faqs.headers.h3')}</h1>
                            <p className='texto1'>{i18n.t('section_faqs.texts.t2.p')} <b>{i18n.t('section_faqs.texts.t2.b')}</b></p>
                            <ol>
                                <li><b>{i18n.t('section_faqs.texts.t2.li1.b')}</b> {i18n.t('section_faqs.texts.t2.li1.p')}</li>
                                <li>{i18n.t('section_faqs.texts.t2.li2.p')} <b>{i18n.t('section_faqs.texts.t2.li2.b')}</b></li>
                                <li>{i18n.t('section_faqs.texts.t2.li3.p')} <b>{i18n.t('section_faqs.texts.t2.li3.b')}</b></li>
                                <li>{i18n.t('section_faqs.texts.t2.li4.p')} <b>{i18n.t('section_faqs.texts.t2.li4.b')}</b></li>
                            </ol>
                        </div>
                    </div>
                    <div>
                        <div className='faq'>
                            <h1 className='h3'>{i18n.t('section_faqs.headers.h4')}</h1>
                            <p className='texto1'>{i18n.t('section_faqs.texts.t3.p1')} <b>{i18n.t('section_faqs.texts.t3.b1')}</b></p>
                            <p className='texto1'>{i18n.t('section_faqs.texts.t3.p2')} <b>{i18n.t('section_faqs.texts.t3.b2')}</b></p>
                            <p className='texto1'>{i18n.t('section_faqs.texts.t3.p3')} <b>{i18n.t('section_faqs.texts.t3.b3')} </b>{i18n.t('section_faqs.texts.t3.p4')}</p>
                        </div>
                        <div className='faq'>
                            <h1 className='h3'>{i18n.t('section_faqs.headers.h5')}</h1>
                            <p className='texto1'>{i18n.t('section_faqs.texts.t4.p1')} <b>{i18n.t('section_faqs.texts.t4.b')}</b> {i18n.t('section_faqs.texts.t4.p2')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionNews;