import SectionIntro from './section_intro';
import SectionResume from './section_resume';
import SectionAbout from './section_about';
import SectionStarBreak from './section_stars_break';
import SectionBenefits from './section_benefits';
import SectionClients from './section_clients';
import SectionFaqs from './sectoin_faqs';
import SectionNews from './section_news';

function Section() {

    
    return (
        <div className="App bg">
            <SectionIntro />
            <SectionResume />
            <SectionAbout />
            <SectionStarBreak />
            <SectionBenefits />
            <SectionClients />
            <SectionNews />
            <SectionFaqs />
        </div>
    );
}

export default Section;