export const eng = {
    navbar: {
        anchors: {
            a1: 'About',
            a2: 'Benefits',
            a3: 'Our clients',
            a4: 'Faqs',
            a5: '¡News!'
        },
        buttons: {
            b1: 'Sign in',
            b2: 'Download'
        }
    },
    section_intro: {
        anchors:{},
        buttons:{
            b1: 'Learn more'
        },
        headers:{
            h1: 'Improve your stories'
        },
        texts:{
            t1: 'Story development and creation assistance in ARC XP'
        },
    },
    section_resume: {
        anchors:{},
        buttons:{},
        headers:{
            h1: 'Improve SEO performance',
            h2: 'Spell checking and suggestions',
            h3: 'Google Trends integration'
        },
        texts:{
            t1: 'Prepare your content to match with SEO analysis and improve your ranking',
            t2: 'Revision and suggestions for spelling improvements. Error marking and possible solutions. Multi-language.',
            t3: 'We use Google Trends to improve positioning, using the most searched words.'
        },
    },
    section_about: {
        anchors:{},
        buttons:{},
        headers:{
            h1: '¿What is Health Check?'
        },
        texts:{
            t1: 'It is a tool that assists editors while they create and develop stories.',
            t2: 'Solving typical issues with spelling suggestions, most searched words and SEO positioning by analyzing them.'
        }
    },
    section_stars_break: {
        anchors:{},
        buttons:{},
        headers:{
            h1: 'Know the performance of your writing team'
        },
        texts:{}
    },
    section_benefits: {
        anchors:{},
        buttons:{},
        headers:{
            h1: 'Health Check benefits',
            h2: '1',
            h3: '2',
            h4: '3',
            h5: '4',
            h6: '5',
            h7: '6',
        },
        texts:{
            t1: 'Find high-performing keywords that improve the quality and relevance of your content.',
            t2: 'Prepare your content to match with SEO analysis',
            t3: 'Verification of links and images using previews.',
            t4: 'Content optimization.',
            t5: 'Spelling check and suggestions.',
            t6: 'Integration with Google Trends to get most searched words.',
        }
    },
    section_clients: {
        anchors:{},
        buttons:{},
        headers:{
            h1: 'Some of our clients'
        },
        texts:{
            t1: '* Available for ARC Publishing users with Chrome',
        }
    },
    section_faqs: {
        anchors:{},
        buttons:{},
        headers:{
            h1: 'Faqs',
            h2: '1. ¿How can I register?',
            h3: '1. ¿How to install the Health Check extension?',
            h4: '2. ¿How to analize a story?',
            h5: '3. ¿Why if I have the highest number of required fields fulfilled, the star score does not appear?',
        },
        texts:{
            t1: {
                p: 'To request access to the extension, ',
                b: 'the user must request email@rmail.com.'},
            t2: {
                p: 'At the moment, the extension ',
                b: 'it is only available for ARC XP and Chrome users',
                li1: {
                    p: 'the extension File',
                    b: 'Dowload '
                },
                li2: {
                    p: 'Open the ',
                    b: 'Chrome Browser'
                },
                li3: {
                    p: 'Click on the ',
                    b: '3 dots in the superior right corner.'
                },
                li4: {
                    p: 'Go to Tools ->',
                    b: 'Extensions'
                },
            },
            t3: {
                p1: 'After completing your writing, ',
                b1: 'click on save.',
                p2: 'A window will appear at the bottom of the screen where the result of your analyzed story will be loaded and',
                b2: 'you will be able to check which elements you have correct and which ones are not',
                p3: 'You will also be able',
                b3: 'to verify the punctuation ',
                p4: 'given to your story.'
            },
            t4: {
                p1: 'The mandatory fields section',
                b: 'must be complete',
                p2: 'so that the score of the story can be activated, since these are the minimum requirements.'
            },   
        }
    },
    footer: {
        anchors:{
            a1: 'About',
            a2: 'Benefits',
            a3: 'Our Clients',
            a4: 'Faqs'
        },
        buttons: {},
        headers: {
            h1: 'About Health Check'
        },
        texts: {}
    },
    404: {
        anchors:{
            a1: 'Go back Home'
        },
        buttons:{},
        headers: {
            h1: 'Oosp! 404'
        },
        texts: {
            t1: "We could not find the page you are requesting'"
        }
    }
}