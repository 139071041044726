import '../styles/section.scss';
import Laptop from '../assets/Laptop.png'
import i18n from '../i18n/index'

function SectionBenefits() {


    return (
        <section className="section_container bg" id='benefits'>
            <img src={Laptop} className='Laptop' alt='Analysis preview' />
            <div className='benefit_container'>
                <h1 className='h2 title'>{i18n.t('section_benefits.headers.h1')}</h1>
                <div className='benefit_cards_container'>
                    <div>
                        <div className='benefit_card'>
                            <h2 className='h2'>{i18n.t(`section_benefits.headers.h2`)}</h2>
                            <p className='texto1'>{i18n.t(`section_benefits.texts.t1`)}</p>
                        </div>
                        <div className='benefit_card'>
                            <h2 className='h2'>{i18n.t(`section_benefits.headers.h3`)}</h2>
                            <p className='texto1'>{i18n.t(`section_benefits.texts.t2`)}</p>
                        </div>
                        <div className='benefit_card'>
                            <h2 className='h2'>{i18n.t(`section_benefits.headers.h4`)}</h2>
                            <p className='texto1'>{i18n.t(`section_benefits.texts.t3`)}</p>
                        </div>
                    </div>
                    <div>
                        <div className='benefit_card'>
                            <h2 className='h2'>{i18n.t(`section_benefits.headers.h5`)}</h2>
                            <p className='texto1'>{i18n.t(`section_benefits.texts.t4`)}</p>
                        </div>
                        <div className='benefit_card'>
                            <h2 className='h2'>{i18n.t(`section_benefits.headers.h6`)}</h2>
                            <p className='texto1'>{i18n.t(`section_benefits.texts.t5`)}</p>
                        </div>
                        <div className='benefit_card'>
                            <h2 className='h2'>{i18n.t(`section_benefits.headers.h7`)}</h2>
                            <p className='texto1'>{i18n.t(`section_benefits.texts.t6`)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionBenefits;