import '../styles/section.scss';
import LogoLa100 from '../assets/Grupo 4@2x.png'
import LogoMitre from '../assets/Grupo 5@2x.png'
import perspectiva_Mitre from '../assets/radiomitre.png'
import perspectiva_La100 from '../assets/la100.png'
import i18n from '../i18n/index'

function SectionClients() {


    return (
        <section className="section_container">
            <div className='clients_container' id='clients'>
                <div className='clients_background'></div>
                <div className='content_container'>
                    <div>
                    <h1 className='texto1'>{i18n.t('section_clients.headers.h1')}</h1>
                    </div>
                    <div className='linksContainer'>
                        <a href='https://radiomitre.cienradios.com/' target='_blank' rel="noreferrer" className='clientLink'>
                            <div className='link_image'>
                                <img src={LogoLa100}  alt='Logo La 100'/> 
                            </div>
                        </a>
                        <a href='https://la100.cienradios.com/' target='_blank' rel="noreferrer" className='clientLink'>
                            <div className='link_image'>
                                <img src={LogoMitre} className='' alt='Logo Radio Mitre' /> 
                            </div>
                        </a>
                    </div>
                </div>
                <p className='comment'>{i18n.t('section_clients.texts.t1')}</p>
                <div className='image_container'>
                    <img src={perspectiva_Mitre} className='p2' alt='Demo in perspective' />
                    <img src={perspectiva_La100} className='p1' alt='Demo in perspective' />
                </div>
            </div>
        </section>
    );
}

export default SectionClients;