import '../styles/footer.scss';
import i18n from '../i18n/index'
import {BsFacebook, BsTwitter, BsInstagram, BsArrowUp} from 'react-icons/bs'

function Footer() {

    const socialLinks = {
        facebook: '',
        instagram: '',
        twitter: '',
    }

    return (
        <div className="footer">
            <div className='container'>
                <div className='content'>
                    <h1 className='h1'>{i18n.t('footer.headers.h1')}</h1>
                    <div className='anchor_container'>
                        <a className='a a-text' href='#about'>{i18n.t('footer.anchors.a1')}</a>
                        <a className='a a-text' href='#benefits'>{i18n.t('footer.anchors.a2')}</a>
                        <a className='a a-text' href='#clients'>{i18n.t('footer.anchors.a3')}</a>
                        <a className='a a-text' href='#faqs'>{i18n.t('footer.anchors.a4')}</a>
                    </div>
                </div>
                <div className='networks'>
                    {socialLinks.facebook && <a className='a a-text' href={socialLinks.facebook}><BsFacebook  size='21px'/></a>}
                    {socialLinks.instagram && <a className='a a-text' href={socialLinks.instagram}><BsInstagram size='21px'/></a>}
                    {socialLinks.twitter && <a className='a a-text' href={socialLinks.twitter}><BsTwitter size='21px'/></a>}
                    <a className='a a-text' href='#top'><BsArrowUp size='21px'/></a>
                </div>
            </div>
        </div>
    );
}

export default Footer;