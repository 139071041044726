import '../styles/section.scss';
import {BsStar, BsStarHalf} from 'react-icons/bs'
import perspectiva_1 from '../assets/perspectiva1.png'
import perspectiva_2 from '../assets/perspectiva2.png'
import perspectiva_3 from '../assets/perspectiva3.png'
import perspectiva_4 from '../assets/perspectiva4.png'
import i18n from '../i18n/index'

function NotFound() {


    return (
        <section className="section_container">
            <div className='intro_container'>
                <div className='intro_content'>
                    <div className='star_container'>
                        <BsStarHalf />
                        <BsStar />
                        <BsStar />
                        <BsStar />
                        <BsStar />
                    </div>
                    <div className='container'>
                        <h1 className='h1'>{i18n.t('404.headers.h1')}</h1>
                        <p className='subtitulo'>{i18n.t('404.texts.t1')}</p>
                        <a className='b-filled' href='/'>{i18n.t('404.anchors.a1')}</a>
                    </div>
                </div>
                
                <div className='image_container'>
                    <img src={perspectiva_3} className='perspective p3' alt='Demo in perspective' />
                    <img src={perspectiva_4} className='perspective p4' alt='Demo in perspective' />
                    <img src={perspectiva_2} className='perspective p2' alt='Demo in perspective' />
                    <img src={perspectiva_1} className='perspective p1' alt='Demo in perspective' />
                </div>
            </div>
        </section>
    );
}

export default NotFound;