import '../styles/section.scss';
import {BsStarFill, BsStarHalf} from 'react-icons/bs'
import i18n from '../i18n/index'

function SectionStarBreak() {


    return (
        <section className="section_container">
            <div className='star_break_container'>
                <div className='star_container'>
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarHalf />
                </div>
                <div className='container'>
                    <h1 className='h1'>{i18n.t('section_stars_break.headers.h1')}</h1>
                </div>
            </div>
        </section>
    );
}

export default SectionStarBreak;